import styled from 'styled-components'

export const StyledPlayers = styled.div`
  padding: 5rem 0;
  .players {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`
