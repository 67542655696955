import styled from 'styled-components'
import Table from '@mui/material/Table'

export const StyledTable = styled(Table)`
  background: rgba(0, 74, 121, 1);
  border: #d8a711 2px solid;
  .MuiTable-root {
    .MuiTableCell-root {
      color: #fff;
    }
  }
`
