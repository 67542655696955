import styled from 'styled-components'

export const StyledTeamInformationModal = styled.div`
  /* background: rgba(0, 74, 121, 0.8); */
  border-radius: 5px;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  margin: 0.125rem;
  padding: 0.25rem;
`
