import styled from 'styled-components'

export const StyledPlayerBox = styled.div`
  align-items: center;
  border: black 1px solid;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;
  padding: 2.75rem 1.25rem;
  width: 100px;
`
