import styled from 'styled-components'

export const StyledSolicitatePassword = styled.form`
  align-items: center;
  background-color: aliceblue;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2.5rem auto;
  width: 300px;
`
